import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  min-width: 100%;
  background: white;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
`;

const Item = styled.span`
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  color: ${props => (props.active ? '#ff3250' : '#aaaaaa')};
  font-size: 16px;
  height: ${props => (props.active ? '37px' : '40px')};
  border-bottom: ${props => props.active && '3px solid #ff3250'};
  transition: 0.1s all ease-out;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  position: sticky;
  top: 48px;
  .scroll {
    overflow-x: scroll;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }
  }
`;

const CategorySwipe = props => {
  return (
    <Wrapper>
      <div className="scroll" id="category-scroll">
        {props.children && (
          <Container>
            {props.children.map((item, index) => (
              <Item active={item.props.active} key={index}>
                {item}
              </Item>
            ))}
          </Container>
        )}
      </div>
    </Wrapper>
  );
};

export default CategorySwipe;
