import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #e6e6e6;
  height: 47px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 300;
  user-select: none;
`;

const Header = () => {
  return (
    <Container>
      <div>Tour Guide</div>
    </Container>
  );
};

export default Header;
