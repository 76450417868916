import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import rootReducer from './modules';

let store;

if (process.env.NODE_ENV === 'development') {
  store = createStore(rootReducer, composeWithDevTools());
} else {
  store = createStore(rootReducer);
}

(function() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const s = document.createElement('script');
  const s1 = document.getElementsByTagName('script')[0];
  s.type = 'text/javascript';
  s.async = true;
  s.defer = true;
  s.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=${window.navigator.language}`;

  s1.parentNode.insertBefore(s, s1);
})();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
