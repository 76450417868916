const SET_DATA = 'list/SET_DATA';
const SET_PAGE = 'list/SET_PAGE';
const SET_CATEGORY = 'list/SET_CATEGORY';
const SET_LOCATION = 'list/SET_LOCATION';

export const setListData = value => ({ type: SET_DATA, payload: value });
export const setPageNo = value => ({ type: SET_PAGE, payload: value });
export const setCategory = value => ({ type: SET_CATEGORY, payload: value });
export const setLocation = value => ({ type: SET_LOCATION, payload: value });

const initialState = {
  data: null,
  category: 0,
  page: 1,
  location: null,
};

const list = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
};

export default list;
