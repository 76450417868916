import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import Rating from './Rating';
import pageIcon from '../images/page.svg';
import locationIcon from '../images/location.svg';
import directionIcon from '../images/direction.svg';
import addressIcon from '../images/address.svg';
import calIcon from '../images/cal.svg';
import timeIcon from '../images/time.svg';
import infoIcon from '../images/info.svg';
import linkIcon from '../images/link.svg';
import phoneIcon from '../images/phone.svg';
import parkIcon from '../images/park.svg';
import queryString from 'query-string';
import Carousel from 'nuka-carousel';
import ReviewItem from './ReviewItem';
import noneImg from '../images/none.png';
import { getFullLanguage, splitTitle, httpToS } from './common';

const apiKey = process.env.REACT_APP_TOUR_API_SERVICE_KEY;
// const mapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000000;
  font-size: 14px;
  min-height: 100vh;
  .head-box {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    text-align: left;
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 8px;
    .title {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.09;
      color: #000000;
    }
    .sub-title {
      margin-top: 4px;
      font-size: 12px;
      line-height: 1.33;
      color: #aaaaaa;
    }
  }
`;

const Section = styled.div`
  border: solid 1px #e6e6e6;
  padding: 1rem 5%;
  background: #f9f9f9;
  line-height: 1.43;
  display: flex;
  align-items: flex-start;
  .left {
    margin-right: 8px;
  }
  div {
    margin-top: 2px;
  }
  .small-view {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 1.43em;
    height: 5.72em;
  }
`;

const Image = styled.div`
  background-image: ${(props) => `url(${props.img})`};
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: ${window.innerWidth * 0.9 * 0.625 + 'px'};
  max-height: 326px;
  margin: 5% 0 2.5% 0;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  .item {
    padding: 8px 0;
    font-size: 12px;
    color: #aaa;
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

const CustomCarousel = styled(Carousel)`
  div {
    outline: none;
  }
  span.paging-dot {
    background-color: white;
  }
`;

const ItemDetail = (props) => {
  const { lang } = props.match.params;
  const [data, setData] = useState(null);
  const [detail, setDetail] = useState(null);
  const [images, setImages] = useState(null);
  const [googleData, setGoogleData] = useState(0);
  const [homepage, setHompage] = useState();

  const [openInfo, setOpenInfo] = useState(false);

  const { cid, gid, dist, type } = queryString.parse(props.location.search);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getMapData = () => {
    const google = window.google;

    const map = new google.maps.Map(document.getElementById('map'));
    const service = new google.maps.places.PlacesService(map);

    service.getDetails(
      {
        placeId: gid,
        language: lang,
        fields: ['reviews', 'rating', 'name'],
      },
      (result, status) => {
        if (status === 'OK') {
          setGoogleData(result);
        }
      }
    );

    // axios
    //   .get(
    //     `https://maps.googleapis.com/maps/api/place/details/json?placeid=${gid}&fields=name,opening_hours,rating,price_level,reviews,formatted_phone_number&key=${mapApiKey}&language=${lang}`,
    //   )
    //   .then(res => {
    //     console.log(res);
    //     if (res.data.status === 'OK') {
    //       setGoogleData(res.data.result);
    //     }
    //   });
  };

  useEffect(() => {
    const url = `https://api.visitkorea.or.kr/openapi/service/rest/${getFullLanguage(
      lang
    )}/detailCommon?contentId=${cid}&
    &serviceKey=${apiKey}&numOfRows=10&pageNo=1&MobileOS=ETC&MobileApp=AppTest&defaultYN=Y&firstImageYN=Y&areacodeYN=N&addrinfoYN=Y&mapinfoYN=Y&overviewYN=Y&_type=json`;
    axios.get(url).then((result) => {
      const item = result.data.response.body.items.item;
      setData(item);
      getMapData();
      // initTmap({ x: item.mapx, y: item.mapy });
    });
    axios
      .get(
        `https://api.visitkorea.or.kr/openapi/service/rest/${getFullLanguage(
          lang
        )}/detailImage?serviceKey=${apiKey}&contentId=${cid}&imageYN=Y&MobileOS=ETC&MobileApp=AppTest`
      )
      .then((result) => {
        setImages(result.data.response.body.items.item);
      });
    axios
      .get(
        `https://api.visitkorea.or.kr/openapi/service/rest/${getFullLanguage(
          lang
        )}/detailIntro?serviceKey=${apiKey}&numOfRows=10&pageSize=10&pageNo=1&MobileOS=ETC&MobileApp=AppTest&contentTypeId=${type}&contentId=${cid}&defaultYN=Y`
      )
      .then((result) => {
        // console.log(result);
        setDetail(result.data.response.body.items.item);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parentEl =
      document.getElementById('web') && document.getElementById('web').getElementsByTagName('a')[0];

    parentEl && setHompage(parentEl.href);
  }, [data]);

  const replaceEnter = (str) => {
    if (str) {
      return str.replace(/(<br>|<br\/>|<br \/>|<BR>|<bR>|<Br>|\*|;|•)/g, '\r\n');
    } else {
      return '';
    }
  };

  return (
    <Container>
      <div className="head-box">
        {data && data.firstimage ? (
          <CustomCarousel renderCenterLeftControls={null} renderCenterRightControls={null}>
            <Image img={httpToS(data.firstimage)} />
            {images &&
              Array.isArray(images) &&
              images.forEach((item, index) => {
                if (index < 7) return <Image img={httpToS(item.originimgurl)} key={index} />;
              })}
          </CustomCarousel>
        ) : (
          <Image img={noneImg} />
        )}

        <div className="title">{data && splitTitle(data.title, 'main', lang)}</div>
        <div className="sub-title">{data && splitTitle(data.title, 'sub', lang)}</div>
        <div
          style={{
            margin: '16px 0',
            display: 'flex',
            alignItems: 'center',
            color: '#aaa',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Rating rating={googleData && googleData.rating} />
            <span style={{ marginLeft: '9px' }}>{(googleData && googleData.rating) || 0}</span>
          </div>
          <div>
            {parseInt(dist) > 1000
              ? (parseInt(dist) / 1000).toFixed(1) + 'km'
              : `${parseInt(dist)}m`}
          </div>
        </div>
      </div>

      <ButtonContainer>
        <a href={homepage} className="item" target="_blank" rel="noopener noreferrer">
          <>
            <img src={pageIcon} alt="" className="icon" />
            <div>Home Page</div>
          </>
        </a>
        <Link to={`/map/detail?x=${data && data.mapx}&y=${data && data.mapy}`} className="item">
          <img src={locationIcon} alt="" />
          <div>Location</div>
        </Link>

        <Link
          to={`/map/direction?dlng=${data && data.mapx}&dlat=${data && data.mapy}`}
          className="item"
        >
          <img src={directionIcon} alt="" />
          <div>Direction</div>
        </Link>
      </ButtonContainer>
      <Section>
        <img src={addressIcon} alt="" className="left" />
        <div>{data && replaceEnter(data.addr1)}</div>
      </Section>
      {detail && detail.usetime && (
        <Section>
          <img src={timeIcon} alt="" className="left" />
          <div dangerouslySetInnerHTML={{ __html: detail.usetime }} />
        </Section>
      )}
      {detail && detail.restdate && (
        <Section>
          <img src={calIcon} alt="" className="left" />
          <div>{replaceEnter(detail.restdate)}</div>
        </Section>
      )}
      <Section>
        <img src={phoneIcon} alt="" className="left" />
        {data && data.tel ? (
          <a href={`tel:${data && data.tel}`} style={{ color: '#ff3250' }}>
            {data && data.tel}
          </a>
        ) : (
          <div>
            {detail && (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    detail.infocenter || detail.infocentershopping || detail.infocenterculture,
                }}
              />
            )}
          </div>
        )}
      </Section>
      {data && data.homepage && (
        <Section>
          <img src={linkIcon} alt="" className="left" />
          <div
            id="web"
            dangerouslySetInnerHTML={{ __html: data.homepage }}
            style={{ color: '#ff3250' }}
          />
        </Section>
      )}

      {detail && (detail.parking || detail.parkingculture || detail.parkingshopping) && (
        <Section>
          <img src={parkIcon} alt="" className="left" />
          <div>
            {replaceEnter(detail.parking || detail.parkingculture || detail.parkingshopping)}
          </div>
        </Section>
      )}
      <Section>
        <img src={infoIcon} alt="" className="left" />
        {openInfo ? (
          <>
            <div>
              {data && <div dangerouslySetInnerHTML={{ __html: data.overview }} />}
              <div
                style={{
                  color: '#55607d',
                  marginTop: '1rem',
                  fontSize: '12px',
                  lineHeight: 1.33,
                }}
              >
                <b>Information offering</b>
                <br />
                Korea Tourism Organization
                <br />
                Google
              </div>
              <div
                style={{ color: '#aaa', cursor: 'pointer' }}
                onClick={() => {
                  setOpenInfo(false);
                }}
              >
                Show Less
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              {data && (
                <div className="small-view" dangerouslySetInnerHTML={{ __html: data.overview }} />
              )}
              <div
                style={{ color: '#aaa', cursor: 'pointer' }}
                onClick={() => {
                  setOpenInfo(true);
                }}
              >
                Read more
              </div>
            </div>
          </>
        )}
      </Section>
      {googleData && googleData.reviews ? (
        <Section>
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Reviews</span>
        </Section>
      ) : (
        <></>
      )}
      {googleData && googleData.reviews ? (
        googleData.reviews.map((item, index) => (
          <Section key={index}>
            <ReviewItem data={item} />
          </Section>
        ))
      ) : (
        <></>
      )}
      <div id="map" style={{ width: 0, height: 0 }} />
    </Container>
  );
};

export default withRouter(ItemDetail);
