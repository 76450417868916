import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import animateScrollTo from 'animated-scroll-to';
import { Link, withRouter } from 'react-router-dom';
import { getFullLanguage, translate } from './common';
import { throttle } from 'lodash';
import axios from 'axios';
import { setListData, setPageNo, setCategory, setLocation } from '../modules/list';
import InfoItem from './InfoItem';
import CategorySwipe from './CategorySwipe';
import Loader from './Loader';
import ani from '../images/ani.png';
import warn from '../images/warning.svg';

const config = {
  apiKey: process.env.REACT_APP_TOUR_API_SERVICE_KEY,
  arrange: 'P',
  contentTypeId: '',
  radius: 4000, // 반경 (m단위)
};

const categories = [
  { name: 'All', key: 'all' },
  { name: 'Tour', key: 'tour' },
  { name: 'Culture', key: 'culture' },
  { name: 'Store', key: 'store' },
];

const InfoList = (props) => {
  const list = useSelector((state) => state.list, []);
  const { lang } = props.match.params;

  const [data, setData] = useState(list.data || []);
  const [page, setPage] = useState(list.page || 1);
  const [currentLocation, setCurrentLocation] = useState(list.location || {});
  const [cateIndex, setCateIndex] = useState(list.category || 0);
  const [status, setStatus] = useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    dispatch(setListData(data));
    dispatch(setPageNo(page));
    dispatch(setCategory(cateIndex));
  }, [page, cateIndex, data, dispatch]);

  const loadLocation = useCallback(() => {
    const { geolocation } = window.navigator;
    if (geolocation) {
      // console.log('위치 가져오기 실행');
      setError(null);
      geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            x: position.coords.longitude,
            y: position.coords.latitude,
          });
          dispatch(
            setLocation({
              x: position.coords.longitude,
              y: position.coords.latitude,
            })
          );
        },
        (err) => {
          err.code === 1 && setError('Cannot find your location.');
          setCurrentLocation({
            x: 126.97702,
            y: 37.579719,
          });
        },
        {
          enableHighAccuracy: false,
          maximumAge: 0,
          timeout: Infinity,
        }
      );
    } else {
      setError(`Geolocation is not supported by your browser`);
      setCurrentLocation({
        x: 126.97702,
        y: 37.579719,
      });
    }
  }, [dispatch]);

  // 현재위치좌표 가져오기
  useEffect(() => {
    if (!list.location) loadLocation();
  }, [list.location, loadLocation]);

  const getMapsDetail = async (array) => {
    const google = window.google;

    const map = new google.maps.Map(document.getElementById('map'));
    const service = new google.maps.places.PlacesService(map);

    const promises = array.map((item) => {
      return new Promise((resolve) => {
        service.findPlaceFromQuery(
          {
            query: item.title,
            fields: ['rating', 'place_id'],
          },
          (result, status) => {
            if (status === 'OK') {
              const googleData = result[0];
              resolve({ ...item, googleData });
            } else {
              resolve(item);
            }
          }
        );
      });
    });
    const value = await Promise.all(promises);
    return value;
  };
  
const url = `http://api.visitkorea.or.kr/openapi/service/rest/EngService/locationBasedList?serviceKey=NBDiZwb8wAeh0dV%2BRMkIc6GneAAr6xB2M875hHpoxyFsoo4oNvV0qZPbB7Pl4jP1fm959OiPu5Aj%2Ftfv5iaTWg%3D%3D&numOfRows=10&pageNo=1&MobileOS=AND&MobileApp=AppTest&listYN=Y&arrange=A&contentTypeId=&mapX=126.981611&mapY=37.568477&radius=1000&modifiedtime=&`;

  useEffect(() => {
    const flag = isFirstRender;
    if (!flag && currentLocation.x && currentLocation.y) {
      setStatus('Loading...');
      setIsLoading(true);
      axios
        .get(url, {
          params: {
            pageNo: page,
            numOfRows: isFirstRender ? 10 : page * 10,
          },
        })
        .then((result) => {
          // console.log(result);
          const resultArr = result.data.response.body.items.item;
          if (resultArr) {
            const googleData = getMapsDetail(resultArr);
            googleData.then((googleResult) => {
              // console.log(googleResult);
              setData(data.concat(googleResult));
              setStatus('See more...');
              setIsLoading(false);
            });
          } else {
            setStatus('End of page');
			console.log(url);
            setIsLoading(false);
          }
        });
    }
  }, [page, currentLocation, isFirstRender]); // eslint-disable-line

  useEffect(() => {
    const catePos = document.getElementById(
      `cate-${categories[cateIndex === 0 ? 0 : cateIndex - 1].key}`
    ).offsetLeft;
    const cateRef = document.getElementById('category-scroll');
    animateScrollTo(catePos - 22, {
      horizontal: true,
      element: cateRef,
      speed: 50,
    });
    // to top
    window.scrollTo(0, 0);
  }, [cateIndex, categories]); // eslint-disable-line

  useEffect(() => {
    const endScroll = throttle(() => {
      let scrollHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight
      );
      let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
      let clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
        setPage((page) => page + 1);
      }
    }, 500);

    window.addEventListener('scroll', endScroll);
    return () => {
      window.removeEventListener('scroll', endScroll);
    };
  }, [page, isLoading]);

  // 여행지, 문화, 쇼핑 3가지 카테고리 타입번호: (76, 78, 79)
  const filterDatabyType = (data, type) => {
    const getData = (type) => {
      switch (type) {
        case 'all':
          return data.filter(
            (item) =>
              item.contenttypeid === 76 || item.contenttypeid === 78 || item.contenttypeid === 79
          );
        case 'tour':
          return data.filter((item) => item.contenttypeid === 76);
        case 'culture':
          return data.filter((item) => item.contenttypeid === 78);
        case 'store':
          return data.filter((item) => item.contenttypeid === 79);
        default:
          return;
      }
    };
    const filtered = getData(type);

    return filtered;
  };

  return (
    <>
      <CategorySwipe>
        {categories.map((category, index) => (
          <span
            active={index === cateIndex}
            onClick={() => {
              setCateIndex(index);
            }}
            id={`cate-${category.key}`}
            key={index}
          >
            {translate(category.key, lang)}
          </span>
        ))}
      </CategorySwipe>
      <div id="map" style={{ width: 0, height: 0 }} />
      {currentLocation && !currentLocation.x && <Loader status="Searching for current location" />}
      {error && (
        <div
          style={{
            background: '#ffe066',
            fontSize: '12px',
            textAlign: 'center',
            padding: '0.25rem 0',
          }}
          onClick={loadLocation}
        >
          <span>
            <img src={warn} alt="" style={{ width: '11px', marginRight: '0.25rem' }} />
          </span>
          {error}
        </div>
      )}
      {data &&
        filterDatabyType(data, categories[cateIndex].key).map((item, index) => (
          <Link
            key={index}
            to={`/${lang}/item/detail?cid=${item.contentid}&gid=${
              item.googleData && item.googleData.place_id
            }&dist=${item.dist}&type=${item.contenttypeid}`}
          >
            <InfoItem data={item} key={index} lang={lang} />
          </Link>
        ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '2rem 0',
          alignItems: 'center',
          color: '#aaa',
          fontSize: '12px',
        }}
      >
        {status === 'Loading...' && (
          <img src={ani} alt="" style={{ width: '40px', marginRight: '0.5rem' }} />
        )}
        {status}
      </div>
    </>
  );
};

export default withRouter(InfoList);
