import React from 'react';
import InfoList from './components/InfoList';
import { Route, Switch } from 'react-router-dom';
import ItemDetail from './components/ItemDetail';
import LocationMap from './components/LocationMap';
import DirectionMap from './components/DirectionMap';
import Header from './layouts/Header';

const App = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={InfoList} />
        <Route exact path="/:lang" component={InfoList} />
        <Route exact path="/:lang/item/detail" component={ItemDetail} />
        <Route exact path="/map/detail" component={LocationMap} />
        <Route exact path="/map/direction" component={DirectionMap} />
      </Switch>
    </>
  );
};

export default App;
