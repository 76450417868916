import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import icon from '../images/32.png';

const MapContainer = styled.div`
  #map {
    width: 100%;
    height: ${window.innerHeight - 48 + 'px'};
  }
`;

const LocationMap = props => {
  const { x, y } = queryString.parse(props.location.search);
  const { google } = window;

  const initMap = useCallback(() => {
    if (!google) return;
    var position = { lat: parseFloat(y), lng: parseFloat(x) };
    const map = new google.maps.Map(document.getElementById('map'), { zoom: 16, center: position });
    new google.maps.Marker({ position: position, map: map, icon });
  }, [google, x, y]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  return (
    <MapContainer>
      <div id="map" />
    </MapContainer>
  );
};

export default withRouter(LocationMap);
