import React from 'react';
import styled from 'styled-components';
import Rating from './Rating';
import noneImg from '../images/none.png';
import { splitTitle, httpToS } from './common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #000000;
  font-size: 14px;
  width: 90%;
  margin: 0 5%;
  border-bottom: solid 1px #e6e6e6;
  .head-box {
    display: flex;
    flex-direction: column;
    text-align: left;
    .title {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.09;
      color: #000000;
    }
    .sub-title {
      margin-top: 4px;
      font-size: 12px;
      line-height: 1.33;
      color: #aaaaaa;
    }
  }
`;

const Image = styled.div`
  background-image: ${props => `url(${props.img})`};
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: ${window.innerWidth * 0.9 * 0.625 + 'px'};
  max-height: 326px;
  margin: 5% 0;
  border-radius: 4px;
`;

const InfoItem = ({ data, lang }) => {
  return (
    <Container>
      {data.firstimage ? (
        <Image img={httpToS(data.firstimage)} />
      ) : (
        <Image img={noneImg} />
      )}
      <div className="head-box">
        <div className="title">
          {data && splitTitle(data.title, 'main', lang)}
        </div>
        <div className="sub-title">
          {data && splitTitle(data.title, 'sub', lang)}
        </div>
        <div
          style={{
            margin: '16px 0',
            display: 'flex',
            alignItems: 'center',
            color: '#aaa',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Rating
              rating={data && data.googleData && data.googleData.rating}
            />
            <span style={{ marginLeft: '9px' }}>
              {(data && data.googleData && data.googleData.rating) || 0}
            </span>
          </div>
          <div>
            {data.dist > 1000
              ? `${(data.dist / 1000).toFixed(2)}km`
              : `${data.dist}m`}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default InfoItem;
