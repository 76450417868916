import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Loader from './Loader';

const MapContainer = styled.div`
  #map {
    width: 100%;
    height: ${window.innerHeight - 48 + 'px'};
  }
`;

const DirectionMap = props => {
  const [isLoading, setIsLoading] = useState(true);
  const { dlat, dlng } = queryString.parse(props.location.search);
  const { google } = window;

  const calculateAndDisplayRoute = useCallback(
    (directionsService, directionsDisplay, lat, lng) => {
      // const selectedMode = document.getElementById('mode').value;
      const selectedMode = `TRANSIT`;
      directionsService.route(
        {
          origin: { lat, lng }, // 현위치
          destination: { lat: Number(dlat), lng: Number(dlng) }, // 도착지
          travelMode: google.maps.TravelMode[selectedMode]
        },
        (response, status) => {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
            setIsLoading(false);
          } else {
          }
        }
      );
    },
    [dlat, dlng, google.maps.TravelMode]
  );

  const initGoogleMap = useCallback(
    (lat, lng) => {
      const directionsDisplay = new google.maps.DirectionsRenderer();
      const directionsService = new google.maps.DirectionsService();
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 15,
        center: { lat, lng }
      });
      calculateAndDisplayRoute(directionsService, directionsDisplay, lat, lng);
      directionsDisplay.setMap(map);
    },
    [
      calculateAndDisplayRoute,
      google.maps.DirectionsRenderer,
      google.maps.DirectionsService,
      google.maps.Map
    ]
  );

  useEffect(() => {
    const { geolocation } = window.navigator;
    if (geolocation) {
      // console.log('위치 가져오기 실행');
      geolocation.getCurrentPosition(
        position => {
          initGoogleMap(position.coords.latitude, position.coords.longitude);
        },
        err => {
          console.log(err);
        },
        {
          enableHighAccuracy: false,
          maximumAge: 0,
          timeout: Infinity
        }
      );
    }
  }, [initGoogleMap]);

  return (
    <MapContainer>
      <div id="map" />
      {isLoading && <Loader status="loading..." />}
    </MapContainer>
  );
};

export default withRouter(DirectionMap);
