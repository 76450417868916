import React from 'react';
import ani from '../images/ani.png';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

const Loader = ({ status }) => {
  return (
    <Container>
      <div>
        <img src={ani} alt="" style={{ width: '60px', maxWidth: '190px' }} />
      </div>
      <div style={{ color: '#aaa' }}>{status}</div>
    </Container>
  );
};

export default Loader;
