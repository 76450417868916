import React, { useState, useEffect } from 'react';
import half from '../images/half.svg';
import full from '../images/full.svg';
import empty from '../images/empty.svg';

import styled from 'styled-components';

const Container = styled.div`
  img {
    margin-right: 3px;
  }
`;

const Rating = ({ rating }) => {
  const [first, setFirst] = useState(empty);
  const [second, setSecond] = useState(empty);
  const [third, setThird] = useState(empty);
  const [fourth, setFourth] = useState(empty);
  const [fifth, setFifth] = useState(empty);

  const setFn = [setFirst, setSecond, setThird, setFourth, setFifth];

  useEffect(() => {
    for (let i = 0; i < 5; i++) {
      setFn[i](empty);
    }
    if (rating) {
      const beforePoint = Math.floor(rating);
      const afterPoint = (rating * 10) % 10; // 소수점

      for (let i = 0; i < beforePoint; i++) {
        setFn[i](full);
      }
      if (afterPoint > 4) {
        setFn[beforePoint](half);
      }
    }
  }, [rating, setFn]);

  return (
    <Container>
      <img src={first} alt="" />
      <img src={second} alt="" />
      <img src={third} alt="" />
      <img src={fourth} alt="" />
      <img src={fifth} alt="" />
    </Container>
  );
};

export default Rating;
