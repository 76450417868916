import React, { useState } from 'react';
import Rating from './Rating';
import styled from 'styled-components';

const SmallView = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.43em;
  height: 4.29em;
`;

const ReviewItem = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <img
        src={data.profile_photo_url}
        className="left"
        style={{ width: '32px' }}
        alt=""
      />
      <div>
        <div>
          <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
            {data.author_name}
          </span>
          <span
            style={{
              fontSize: '13px',
              color: '#aaa',
              marginLeft: '8px',
            }}
          >
            {data.relative_time_description}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '6px',
          }}
        >
          <Rating rating={data.rating} />
          <span style={{ marginLeft: '9px', color: '#aaa' }}>
            {data.rating}
          </span>
        </div>
        {open ? (
          <div style={{ marginTop: '12px' }}>{data.text}</div>
        ) : (
          <>
            <SmallView style={{ marginTop: '12px' }}>{data.text}</SmallView>
            {data.text.length > 110 && (
              <div
                onClick={() => {
                  setOpen(true);
                }}
              >
                <span style={{ color: '#aaa' }}>Read More</span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ReviewItem;
